:root {
  --color-brand--1: #ffb545;
  --color-brand--2: #00c46a;
  --color-dark--1: #ffffff;
  --color-dark--2: #42484d;
  --color-light--1: rgb(3, 3, 3);
  --color-light--2: #ececec;
  --color-light--3: rgb(214, 222, 224);
}

.thisis {
  display: flex;
  box-shadow: 0 4px 6px rgb(0 0 0 / 40%);
}

thisis {
  font-family: "Manrope", sans-serif;
  color: var(--color-light--2);
  font-weight: 400;
  /* line-height: 1.6; */
  height: 600px;
  width: 100%;
  margin: auto;
  overscroll-behavior-y: none;
  background-color: #fff;
  /* padding: 2.5rem; */
  display: flex;
  /* flex-direction: column; */
}

.sidebar {
  width: 30%;
  height: 100vh;
  overflow: auto;
  box-shadow: 0 4px 10px rgb(0 0 0 / 40%);

  margin-right: 1rem;
  /* background: whitesmoke; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: #a1958c;
}

sidebar {
  width: 50%;
  /* flex-basis: 20rem; */
  background-color: var(--color-dark--1);
  padding: 3rem 5rem 4rem 5rem;
  display: flex;
  flex-direction: column;
}

/* .logo {
  height: 5.2rem;
  align-self: center;
  margin-bottom: 4rem;
} */
/* 
.workouts {
  list-style: none;
  height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
} */
.workouts {
  list-style: none;
  background-color: #fff;
}

.workouts::-webkit-scrollbar {
  width: 0;
}

.workout {
  /* background-color: var(--color-dark--2); */
  /* background-color: #7997aa; */
  border-radius: 5px;
  padding: 0.2rem 2rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
  /* display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem 1.5rem; */
}

.workout:hover {
  background-color: #f4f4f4;
}

.workout--running {
  border-left: 5px solid var(--color-brand--2);
}

.workout--cycling {
  border-left: 5px solid var(--color-brand--1);
}

.workout__title {
  font-size: 1.7rem;
  font-weight: 600;
  grid-column: 1 / -1;
}

.workout__details {
  display: flex;
  /* align-items: baseline; */
}

.workout__icon {
  font-size: 1.8rem;
  margin-right: 0.2rem;
  height: 0.28rem;
}

.workout__value {
  display: inline;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.workout__unit {
  font-size: 1.1rem;
  color: var(--color-light--1);
  text-transform: uppercase;
  font-weight: 800;
}

.form {
  background-color: #f0f0ffbd;

  border-radius: 5px;
  padding: 1rem 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  margin-top: 0.3rem;

  /* margin-left: 2rem; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 0.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  /* Match height and activity boxes */
  height: 20.25rem;
  transition: all 0.5s, transform 1ms;
}

.form.hidden {
  transform: translateY(-30rem);
  height: 0;
  padding: 0 2.25rem;
  margin-bottom: 0;
  opacity: 0;
}

.form__row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form__row--hidden {
  display: none;
}

.form__label {
  /* flex: 0 0 50%; */
  font-size: 0.7rem;
  font-weight: 600;
  color: white;
}

.form__input {
  width: 100%;
  padding: 0.9rem 1.1rem;
  font-family: inherit;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  background-color: #fff;
  /* background-color: var(--color-light--3); */
  transition: all 0.2s;
}

.form__input:focus {
  outline: none;
  background-color: #ffffffd8;
}

.form__btn {
  display: none;
}

.copyright {
  margin-top: auto;
  font-size: 1.3rem;
  text-align: center;
  color: var(--color-light--1);
}

.twitter-link:link,
.twitter-link:visited {
  color: var(--color-light--1);
  transition: all 0.2s;
}

.twitter-link:hover,
.twitter-link:active {
  color: var(--color-light--2);
}

/* GENERAL */
.thisis a:link,
.thisis a:visited {
  color: var(--color-brand--1);
}

/* SIDEBAR */

/* MAP */
#map {
  height: calc(100vh - 100px);
  /* width: 100vw; */
  /* flex: 1;
  height: 100%;
  background-color: var(--color-light--1); */
}

/* Popup width is defined in JS using options */
.leaflet-popup .leaflet-popup-content-wrapper {
  /* position: relative;
  text-align: center; */
  background-color: var(--color-dark--1);

  color: var(--color-light--1);
  border-radius: 5px;
  padding-right: 0.6rem;
}

.leaflet-popup-content p {
  margin: 0px;
}

.leaflet-popup .leaflet-popup-content {
  font-size: 1rem;
}

.leaflet-popup .leaflet-popup-tip {
  background-color: var(--color-dark--1);
}

.popup .leaflet-popup-content-wrapper {
  border-left: 5px solid var(--color-brand--2);
}

/* modal by me*/
/* button {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity: 1;
} */

/* Float cancel and delete buttons and add an equal width */
.cancelbtn,
.deletebtn {
  float: left;
  width: 50%;
}

/* Add a color to the cancel button */
.cancelbtn {
  background-color: #ccc;
  color: black;
}

/* Add a color to the delete button */
.deletebtn {
  background-color: #f44336;
}

/* Add padding and center-align text to the container */
/* .container {
  padding: 16px;
  text-align: center;
} */

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  /* background-color: #474e5d; */
  padding-top: 50px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 5px;
}

/* The Modal Close Button (x) */
.close {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 40px;
  font-weight: bold;
  color: #f1f1f1;
}

.close:hover,
.close:focus {
  color: #f44336;
  cursor: pointer;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ul {
  padding-left: 0;
}

.button-radius {
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.leaflet-bottom {
  display: none;
}