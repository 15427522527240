@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }
}


.widget-position-right {
  display: none !important;
}

.backtop {
  display: none !important;
}

.contact-box {
  display: none !important;
}

.flyMessage {
  display: none !important;
}

.chrome {
  display: none !important;
}

.whatsapp_widget_header_icon {
  overflow: hidden;
}

.whatsapp_widget_header_icon img {
  width: 100%;
}

#overview h2,
#overview h1,
#overview h3,
#overview h4,
#overview span,
#overview p,
#overview strong,
#overview div {
  color: #000 !important;
}

/* #overview h2:after {
  position: absolute;
  content: '';
  height: 2px;
  bottom: 12px;
  margin: 0 auto;
  left: 0;
  width: 120px;
  background: #233a65;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
} */